<template>
  <div class="home">
    <div class="filter-wrapper">
      <div>
        <call-input
          v-model="dataSearch"
          type-input="search"
          placeholder="Search"
          @input="onSearch">
          <template slot="prefix">
            <font-awesome-icon
              :icon="dataIconSearch" />
          </template>
        </call-input>
        <v-select
          v-if="computedIsAdmin"
          v-model="dataSelectTeamLeaderValue"
          :options="dataSelectOptionsTeamLeader"
          label="name"
          :class="['call-select-modal call-select', dataSelectTeamLeaderValue ? 'call-select--value' : null]"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="All Leaders">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя Team Leader
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
      <div>
        <call-pagination
          :obj-pagination="dataObjPagination"
          @on-pagination="$_ajax" />
        <call-button
          v-if="computedIsAdmin"
          :icon="dataAddIcon"
          type="success"
          @click="clickShowAddUser()">
          Add Team
        </call-button>
      </div>
    </div>
    <div class="table-wrapper">
      <b-table
        striped
        hover
        no-local-sorting
        :fields="dataMixinTeamsFields"
        :items="dataTeamsList"
        @sort-changed="onSortTabel"
        @row-clicked="(value) => onActions(value, 'view')"
        @row-hovered="onHover"
        @row-unhovered="onUnHover">
        <template #head(open_free_tasks_count)>
          <span class="warning">
            Open
          </span>
          Free
        </template>

        <template #head(open_assigned_tasks_count)>
          <span class="warning">
            Open
          </span>
          Assigned
        </template>

        <template #head(auto_closed_free_tasks_count)>
          <span>
            Closed
          </span>
          Free
        </template>

        <template #head(auto_closed_assigned_tasks_count)>
          <span class="success">
            Closed
          </span>
          Assigned
        </template>
        <template #head(auto_closed_assigned_vip_tasks_count)>
          <span class="success">
            Closed
          </span>
          VIP
        </template>

        <template #head(manually_closed_tasks_count)>
          <span class="danger">
            Closed
          </span>
          Manually
        </template>

        <template #cell(auto_closed_assigned_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_assigned_tasks_count }}</span>
        </template>
        <template #cell(auto_closed_assigned_vip_tasks_count)="data">
          <span class="success">{{ data.item.auto_closed_assigned_vip_tasks_count }}</span>
        </template>
        <template #cell(manually_closed_tasks_count)="data">
          <span class="danger">{{ data.item.manually_closed_tasks_count }}</span>
        </template>

        <template #cell(operators_count)="data">
          {{ data.item.operators_count || 0 }}
        </template>
        <template #cell(projects)="data">
          <span class="danger">{{ data.item.projects_active_count }}</span> | <span class="success">{{ data.item.projects_closed_count }}</span>
        </template>
        <template #cell(isBlocked)="data">
          <font-awesome-icon
            v-if="!data.item.is_active"
            class="tabel-icon--red"
            :icon="dataIconLock" />
          <font-awesome-icon
            v-else
            class="tabel-icon--green"
            :icon="dataIconCheck" />
        </template>
        <template #cell(actions)="data">
          <call-table-teams-actions
            v-show="data.item.actions"
            :block="!data.item.is_active"
            @on-action="(value) => onActions(data.item, value)" />
        </template>
      </b-table>
    </div>
    <call-modal-add-team
      v-model="dataShowAddTeam"
      @on-update="() => $_ajax()" />
    <call-modal-update-team
      v-model="dataShowUpdateTeam"
      :team-id="dataShowUpdateTeamId"
      @on-update="() => $_ajax()" />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue';
import CallButton from '../../../components/common/CallButton';
import { faCheck, faPlus, faPowerOff, faSearch } from '@fortawesome/free-solid-svg-icons';
import CallInput from '../../../components/common/CallInput';
import { mapGetters } from 'vuex';
import { TEAMS_BLOCKED, TEAMS_GET_ALL, USERS_GET_ALL, GET_SMS_SCHEMAS } from '../../../store/types/actions.types';
import debounce from 'lodash/debounce';
import teamsTable from '../../../mixins/table/teamsTable';
import CallModalAddTeam from '../../../components/common/modals/CallAddTeam';
import selectTeamLeader from '../../../mixins/select/selectTeamLeader';
import CallModalUpdateTeam from '../../../components/common/modals/CallUpdateTeam';
import CallPagination from '../../../components/desktop/CallPagination';
import helper, { tabelCell } from '../../../service/helper';
import { ID_ROLE_TEAM_LEADER } from '../../../service/consts';
import CallTableTeamsActions from '../../../components/desktop/CallTableTeamsActions';

export default {
  name: 'PageTeams',
  components: {
    CallTableTeamsActions,
    CallPagination,
    CallModalUpdateTeam,
    CallModalAddTeam,
    CallInput,
    CallButton,
    BTable
  },
  mixins: [teamsTable, selectTeamLeader],
  data () {
    return {
      dataAddIcon: faPlus,
      dataIconSearch: faSearch,
      dataIconCheck: faCheck,
      dataIconLock: faPowerOff,
      dataShowAddTeam: false,
      dataShowUpdateTeam: false,
      dataShowUpdateTeamId: null,
      dataTeamsList: [],
      dataSearch: null,
      dataDesc: null,
      dataOrderBy: null,
      dataObjPagination: {}
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin'
    })
  },
  watch: {
    dataSelectTeamLeaderValue () {
      this.$_ajax({ reset: true });
    }
  },
  async created () {
    this.$_ajax = ((options = {}) => {
      let _page = 0;
      let _limit = tabelCell();

      const { currentRoute: { name: pageName } = {} } = this.$router;

      const _filterKey = `_filter${pageName}`;

      if (!helper.isEmpty(this.$route.query, _filterKey)) {
        try {
          options = helper.clearObject(JSON.parse(this.$route.query[_filterKey]));

          const _arrFilter = [
            'search',
            'leadId',
            'desc',
            'orderBy',
            'limit',
            'page'
          ];

          for (const _key of _arrFilter) {
            if (!helper.isEmpty(options, _key)) {
              switch (_key) {
                case 'search':
                  this.dataSearch = options[_key];
                  break;
                case 'leadId':
                  this.dataSelectTeamLeaderValue = options[_key];
                  break;
                case 'desc':
                  this.dataDesc = options[_key];
                  break;
                case 'orderBy':
                  this.dataOrderBy = options[_key];
                  break;
                case 'limit':
                  _limit = options[_key];
                  break;
                case 'page':
                  _page = options[_key];
                  break;
              }
            }
          }
        } catch (e) {
          console.log('Error query params');
        }
      }

      return async ({ search = this.dataSearch, leadId = this.dataSelectTeamLeaderValue, desc = this.dataDesc, orderBy = this.dataOrderBy, limit = null, page = null, reset = false } = options) => {
        if (!helper.isEmpty(page) && page !== _page) {
          _page = page;
        }

        if (!helper.isEmpty(limit) && limit !== _limit) {
          _limit = limit;
        }

        if (reset) {
          _page = 0;
        }

        const { payload: { list = [] }, misc: { paging = {} } } = await this.$store.dispatch(`team/${TEAMS_GET_ALL}`, {
          params: {
            search,
            lead_id: leadId?.id,
            desc,
            order_by: orderBy,
            limit: _limit,
            page: _page
          }
        });
        this.dataObjPagination = paging;
        this.dataTeamsList = list.map((item) => {
          item.actions = false;
          return item;
        });

        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            [_filterKey]: JSON.stringify(helper.clearObject({
              search,
              leadId,
              desc,
              orderBy,
              limit: _limit,
              page: _page
            }))
          }
        }).catch(() => {});

        options = {};
      };
    })();

    const [{ payload: { list: listTeamLeader = [] } }] = await Promise.all([
      this.$store.dispatch(`user/${USERS_GET_ALL}`, {
        params: {
          role: ID_ROLE_TEAM_LEADER,
          short: true
        }
      }),
      this.$_ajax(),
      this.$store.dispatch(`misc/${GET_SMS_SCHEMAS}`)
    ]);
    this.dataSelectOptionsTeamLeader = listTeamLeader;
  },
  methods: {
    clickShowAddUser () {
      this.dataShowAddTeam = true;
    },
    onSortTabel ({ sortBy, sortDesc }) {
      this.dataDesc = sortDesc;
      this.dataOrderBy = sortBy;
      this.$_ajax();
    },
    onSearch: debounce(function () {
      this.$_ajax({ reset: true });
    }, 1200),
    async  onActions ({ id: teamId, is_active: isBlocked, name = '' }, action) {
      switch (action) {
        case 'update':
          this.dataShowUpdateTeamId = teamId;
          this.dataShowUpdateTeam = true;
          break;
        case 'view':
          this.$router.push({
            name: 'TeamDetail',
            params: {
              id: teamId
            },
            query: {
              teamName: name
            }
          });
          break;
        case 'blocked':
          try {
            await this.$store.dispatch(`team/${TEAMS_BLOCKED}`, {
              teamId,
              is_blocked: !isBlocked
            });
            await this.$_ajax();
            this.$Notify({
              title: 'Team',
              message: `Successfully ${isBlocked ? 'locked' : 'unlocked'}`,
              type: 'success'
            });
          } catch (e) {
            throw Error('Ошибка блокировки');
          }
          break;
      }
    }
  }
};
</script>
